<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Dashboard</h3>

			<div>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-filter-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-row>
				<v-col cols="12" md="4">
					<v-card>
						<v-card-title>
							<div class="overline grey--text">COMPANY REVENUE</div>
						</v-card-title>

						<v-card-text class="pt-0">
							<v-sparkline
								:value="graphs['revenue']"
								color="success"
								height="80"
								padding="24"
								stroke-linecap="round"
								smooth
							>
								<template v-slot:label="item">
									${{ item.value }}
								</template>
							</v-sparkline>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="4">
					<v-card>
						<v-card-title>
							<div class="overline grey--text">TASKS COMPLETED</div>
						</v-card-title>

						<div class="pb-4 px-4">
							<v-sparkline
								:value="graphs['tasks']"
								color="red"
								height="80"
								padding="24"
								stroke-linecap="round"
								smooth
							>
								<template v-slot:label="item">
									{{ item.value }}
								</template>
							</v-sparkline>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" md="4">
					<v-card>
						<v-card-title>
							<div class="overline grey--text">LEADS CONVERTED</div>
						</v-card-title>

						<div class="pb-4 px-4">
							<v-sparkline
								:value="graphs['leads']"
								color="amber"
								height="80"
								padding="24"
								stroke-linecap="round"
								smooth
							>
								<template v-slot:label="item">
									{{ item.value }}
								</template>
							</v-sparkline>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" md="4">
					<v-card height="100%">
						<v-card-title>
							<div class="overline grey--text">FOLLOW UPS</div>
							<v-spacer></v-spacer>
							<v-btn x-small outlined color="grey">View All</v-btn>
						</v-card-title>

						<div class="pb-4 px-4">
							<v-timeline class="fsk-v-timeline" align-top dense>
								<v-timeline-item
									v-for="item in followups"
									:key="item.time"
									icon-color="primary"
									:icon="item.icon"
									small
								>
								<div>
									<div class="font-weight-normal">
									<strong>{{ item.type }}</strong> @{{ item.time }}
									</div>
									<div class="text-caption">{{ item.desc }}</div>
								</div>
								</v-timeline-item>
							</v-timeline>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" md="8">
					<v-card height="100%">
						<v-card-title>
							<div class="overline grey--text">TASKS</div>
							<v-spacer></v-spacer>
							<v-btn x-small outlined color="grey">View All</v-btn>
						</v-card-title>

						<div class="pb-4 px-4">
							<v-list class="pa-0" two-line>
								<template v-for="item in tasks">
									<v-list-item
										:key="item.title"
									>
										<v-list-item-avatar>
											<v-img :src="item.avatar"></v-img>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-list-item-title>{{ item.title }}</v-list-item-title>
											<v-list-item-subtitle><span class='text--primary'>{{ item.assigned.join(', ') }}</span> &mdash; {{ item.desc }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-list>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			followups: [
				{
					type: 'Meeting',
					desc: 'CRM modules & features walk through',
					time: '10:45am',
					icon: 'mdi-account-multiple',
				},
				{
					type: 'Email',
					desc: 'Quotation finalization',
					time: '10:30am',
					icon: 'mdi-email',
				},
				{
					type: 'Call',
					desc: 'Marketing Sprint catchup',
					time: '1:00pm',
					icon: 'mdi-phone-classic',
				},
				{
					type: 'Call',
					desc: 'Bug report & additional features understanding',
					time: '5:30pm',
					icon: 'mdi-phone-classic',
				},
			],
			tasks: [
				{
					avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
					title: 'Brainstorming brunch this weekend',
					assigned: ['Ali Connors'],
					desc: "I'll be in your neighborhood doing errands this weekend. Let's meet up for brunch to brainstorm for the app.",
				},
				{
					avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
					title: 'Weekly marketing sprint',
					assigned: ['Alex', 'Scott', 'Jennifer'],
					desc: "I'm out of town this monday. We can set up a Zoom call for this catchup.",
				},
				{
					avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
					title: 'Review team budget',
					assigned: ['Sandra Adams'],
					desc: "Do you have Paris recommendations? Review & close the team budget so I can forward it to accounts.",
				},
				{
					avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
					title: 'Christmas gifts planning - 2020',
					assigned: ['Trevor Hansen'],
					desc: "Have any ideas about what we should get individual team memebers for Christmas this year?",
				},
			],
			graphs: {
				revenue: [
					423,
					446,
					675,
					510,
					590,
					610,
					760,
				],
				tasks: [
					10,
					20,
					30,
					10,
					10,
					10,
					50,
					20,
				],
				leads: [
					200,
					100,
					134,
					242,
					102,
					313,
					313,
				]
			}
		}),
	}
</script>
